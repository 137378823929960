
@defer (when dataLoaded) {
    <p-panel header="{{ 'Sondes' | transloco }}">
        @if(canWrite()) {
            <div class="grid">
                <div class="col">
                    <button pButton type="button" [label]="'Acheter une sonde' | transloco" icon="fa fa-cart-shopping" class="p-button-cart " (click)="acheterSonde()" ></button>  
                </div>
                <div class="col text-right">
                    <button pButton type="button"  [label]="'Ajouter une sonde' | transloco" icon="pi pi-plus" class="p-button-success" (click)="addSensor()" ></button>  
                </div>
            </div>
        }
        <p-table 
            [value]="sensors" 
            styleClass="p-datatable-gridlines p-datatable-striped"
            stateStorage="session"
            stateKey="sensors-list-session"
            [paginator]="true"
            rowGroupMode="rowspan" 
            groupRowsBy="client.name" 
            [rows]="nbRows"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="{totalRecords} {{ 'élément(s)' | transloco }}" 
            customRowGroup
            >
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="client.name">{{ 'Licence' | transloco }} <p-sortIcon field="client.name"></p-sortIcon></th>
                    <th pSortableColumn="label">
                        {{ 'Libellé' | transloco }}
                        <p-sortIcon field="label"></p-sortIcon>
                    </th>
                    <th pSortableColumn="externalCode">{{ 'Code' | transloco }} <p-sortIcon field="externalCode"></p-sortIcon></th>
                    <th pSortableColumn="workspace.name">{{ 'Zone' | transloco }} <p-sortIcon field="workspace.name"></p-sortIcon></th>
                    <th style="min-width: 100px;"><i class="fa fa-temperature-half"></i></th>
                    <th></th>
                </tr>
                <tr>
                    <th>
                        <p-columnFilter field="client.id" matchMode="in" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect 
                                    [ngModel]="value" 
                                    [options]="licences" 
                                    placeholder="Toutes" 
                                    (onChange)="filter($event.value)" 
                                    optionLabel="displayName" 
                                    optionValue="id"
                                    class="liste-recherche-table"
                                    >
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="label" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="externalCode" matchMode="contains"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter field="cliId" matchMode="in" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect 
                                    [ngModel]="value" 
                                    [options]="licences" 
                                    placeholder="Toutes" 
                                    (onChange)="filter($event.value)" 
                                    optionLabel="displayName" 
                                    optionValue="id"
                                    >
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="workspace.name" matchMode="contains"></p-columnFilter>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template 
                pTemplate="body" 
                let-sensor
                let-rowIndex="rowIndex" 
                let-rowgroup="rowgroup" 
                let-rowspan="rowspan">
                <tr [ngClass]="tableGroupService.getGroupClass(nbRows, rowIndex, rowspan)">
                    <td *ngIf="rowgroup" [attr.rowspan]="rowspan" class="regroup-column" >
                        {{ sensor.client.name }}
                    </td>
                    <td>{{ sensor.label }}</td>
                    <td>{{ sensor.externalCode }}</td>
                    <td>{{ sensor.workspace.name }}</td>
                    <td>
                        <div class="grid grid-nogutter align-items-center ">
                            @if(sensor.lastMeasure) {
                                <div class="col text-right last-temperature" [ngClass]="valueInRanges(sensor)">
                                    {{ sensor.lastMeasure.toFixed(1) }}°C
                                </div>
                            } 
                            @else {
                                <div class="col text-center">--</div>
                            }
                            @if(sensor.minTemp !== null && sensor.maxTemp !== null) {
                                <div class="col text-right">
                                    <div class="borneTemp">{{ sensor.minTemp }}°C</div>
                                    <div class="borneTemp">{{ sensor.maxTemp }}°C</div>
                                </div>
                            }
                        </div>
                    </td>
                    <td class="flex align-items-center justify-content-center gap-3">
                        <a (click)="graphSensor(sensor)" title="{{ 'Historique des mesures' | transloco}}">
                            <i class="pi pi-chart-line"></i>
                        </a>
                        @if(canWrite()) {
                            <a (click)="editSensor(sensor)" title="{{ 'Modifier la sonde' | transloco}}">
                                <i class="pi pi-pencil"></i>
                            </a>
                            
                            <a (click)="deleteSensor(sensor)" title="{{ 'Supprimer la sonde' | transloco}}">
                                <i class="pi pi-trash"></i>
                            </a>
                        }
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-panel>
}
@placeholder {
    <div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>